// Social Share MIXIN
// multistep springboard generated social share icon styles
@mixin sb-social-share-icons($icon-size: 45px, $social-img-size: 25px, $line-height: 40px) {
  border: none;
  padding: 0 !important;
  border-radius: 50%;
  width: $icon-size;
  height: $icon-size;
  display: block;
  text-align: center;
  line-height: $line-height;
  span {
    display: none;
  }
  img {
    width: $social-img-size !important;
    height: $social-img-size !important;
  }
  &.sb_social_button_twitter {
    background-color: $twitter;
  }
  &.sb_social_button_facebook {
    background-color: $facebook;
  }
  &.sb_social_button_email {
    background-color: $email;
  }
}
