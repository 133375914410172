.node-type-donation-form .multistep-donation.default-form-wrapper {

  .form-required{
    display: none;
  }
  .form-item label.error {
    display: none !important;
    // TODO: determine whether we still need these messages for accessibility
    // and if not, remove all related code
    margin-left: 0px;
    color: $red-AA-compliant;
    margin-top: 0;
    padding-top: 0;
  }

  div.error.other-amount-wrapper .currency-in-input input#edit-submitted-donation-other-amount {
    border: 2px solid $red-AA-compliant;
  }

  .form-item .currency-in-input label.error{
    max-width: none;
    &::after {
      content: ". The minimum payment is $5.00.";
    }
  }

  #webform-component-donation--other-amount label.error,
  #webform-component-donation--recurring-other-amount label.error {
    display: block;
  }

  #content .message-wrapper div.error,
  .pin-form-top .step-errors.error,
  #content .step-errors.error {
    @include validation-error
  }
  .pin-form-top .step-0 .step-errors.error,
  #content .step-0 .step-errors.error {
      margin-top: 10px;
      margin-bottom: 0;
      padding-bottom: 0;
  }
  .pin-form-top div.form-item.error,
  #content div.form-item.error {
    input {
      border: 2px solid $red-AA-compliant;
    }
    &::after {
      position: absolute;
      top: -9px;
      right: -8px;
      width: 18px;
      height: 18px;
      background-image: icon-url("error_exclamation_red.png");
      background-size: 18px 18px;
      content: "";
      z-index: 1;
    }
}
  .pin-form-top .step-errors.error span,
  #content .step-errors.error span {
      @include font-family-standard-bold;
  }

  div.error,
  .pin-form-top div.error,
  #content div.error,
  .error input,
  .error select,
  .error textarea,
  tr.error {
      border: none;
      position: relative;
  }

  .error .custom-select {
    color: $offblack;
    > span {
      border-color: $red-AA-compliant;
    }
  }
  .control-group.error input,
  .webform-component-textfield.error {
    color: $offblack;
  }
}
