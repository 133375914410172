.eft-terms-modal {
  display: none;
  position: absolute; 
  /* .header-webform .logo-nav li has z-index of 498 */
  z-index: 500; 
  padding-top: 25px; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3); /* background Opacity for the modal  */
  .eft-terms-modal-content {
    background-color: $white;
    margin: auto;
    width: 90%;
    height: auto;
    max-width: 500px;
    max-height: 620px;
    padding: 15px;
    overflow: visible;
    font-size: $size-6;
  }
  h3 {
    font-size: $size-6;
  }
  .terms-help {
    font-size: $size-7;
  }
  .eft-terms-modal-text {
    padding: 5px;
    line-height: normal;
    h3 {
      margin-top: 10px;
    }
  }
  div.read-terms {
    overflow-y: scroll;
    overflow-x: hidden;
    width: auto;
    height: 270px;
    border: solid 2px;
    margin-bottom: 10px;
    padding: 15px;
    &.error {
      border: solid 2px $red !important;
      color: inherit;
      padding: 15px;
    }
    @media screen and (max-width: 414px) {
      height: 33vh;
    }
    @media screen and (orientation: landscape) 
    and (max-width: calc($desktop - 1px)) {
      height: 28vh;
    }
  }
  .eft-terms-modal-close {
    background: none;
    height: 48px;
    width: 48px;
    margin-right: -15px;
    border: 0;
    padding: 0;
    color: $dark-navy;
    float: right;
    font-size: 30px;
    font-weight: bold;
    &:hover, &:focus {
      cursor: pointer;
    }
  }
  button {
    flex: 1;
    font-size: $size-6;
    border: 2px solid $black;
    text-transform: none;
    [aria-disabled="true"],
    &.disabled {
      background-color: $grey-20 !important;
      border: $grey-20;
      color: $grey-80;
      &:hover{
        opacity: .75;
        color: inherit;
      }
    }
  }
  @media screen and (max-width: calc($desktop - 1px)) {
    position: fixed; 
  }
  @media screen and (orientation: landscape) 
  and (max-width: calc($desktop - 1px)) {
    padding-top: 10px;
  }
}
#eft-terms-container {
  padding-top: 15px;
}
.terms-error {
  display: none;
  color: $red !important;
  font-size: $size-7;
}
.terms-accepted {
  color: #04AE16;
  display: none;
  margin-top: 38px;
  font-size: $size-7;
  &::before {
    content: url("data:image/svg+xml,%3csvg width='13' height='13' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='7' cy='7' r='7' fill='%2304AE16'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.28319 9.77897L7.28506 9.78084L6.22163 10.8443L2 6.62262L3.06342 5.5592L6.22103 8.71682L10.9378 4L12 5.06216L7.28319 9.77897Z' fill='white'/%3e%3c/svg%3e");
    margin-right: 6px;
  }
}

.use-different-payment {
  &.show {
    display: block;
  }
  padding-top: 28px;
  display: none;
  font-size: $size-7;
  color: $black;
  text-decoration: underline !important;
}

.terms-buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  .decline-terms {
    background-color: $white !important;
    color: $black;
  }
  .accept-terms {
    background-color: $black;
    color: $white !important;
    &:hover {
      background-color: $black !important;
      opacity: 0.75;
    }
  }
}
button {
  &.review-terms {
    background-color: $blue !important;
    display: none;
    height: auto !important;
    line-height: normal !important;
    min-height: 44px;
  }
}

fieldset.step-3 .progress-buttons {
  margin-top: 12px !important;
}

body {
  &.modal-popup {
    // also toggle breadcrumb visibility
    // which always stayed on top even after changing the z-index
    .donation-steps {
      visibility: hidden !important;
    }
    .eft-terms-modal {
      display: inline-block;
    }
  }
  @media screen and (max-width: calc($desktop - 1px)) {
    &.modal-popup {
      height: 100vh;
      width: 100vw;
      overflow: hidden !important;
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overscroll-behavior: none;
      position: fixed;
    }
  }
}