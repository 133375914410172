.select {
  position: relative;
  margin-bottom: 25px;

  label {
    position: absolute;
    top: 7px;
    left: 8px;
    z-index: 1;

    &.error.valid {
      display: none!important;
    }
  }
  &.auto-size {
    width: auto;
  }
}

  /* Wrapper element to enable positioning */

.custom-select-container {
  position: relative;
  &.auto-size {
    width: auto;
  }
  .ajax-progress .throbber {
    margin-top: 5px;
  }
}

  /* Set the dimensions of the replaced select element and make it transparent. */

select.custom.replaced {
  height: 60px;
  position: relative;
  width: 100%;
  border: 0;
  filter: alpha(opacity = 0);
  /* Move to IE-specific CSS file if you want to. */
  opacity: 0;
  padding: 0;
  margin: 0;
  z-index: 10;
}

.custom-select {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  > span {
    display: block;
    padding: 25px 25px 5px 10px;
    border: 2px solid black;
    background: icon-url("down_arrow_select_bold_black.png") no-repeat;
    background-size: 14px 9px;
    background-position: right 10px center;
    line-height: 1.5;
    pointer-events: none;
    height: 60px;
  }
  span span {
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  &.active > span {
    border-color: #666;
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
}
