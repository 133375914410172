.page-node-done .multistep-donation.default-form-wrapper.confirmation {

    h1,
    h2,
    h3,
    h4,
    h4 a {
        color: $offblack;
    }

    h4 a:hover {
        color: $grey-60;
    }

    #content {
        position: relative;
        margin-top: 40px;
        @media screen and (max-width: calc($tablet - 1px)) {
            margin-top: 0px;
        }

        h1 {
            padding-top: 0px;
        }
    }

    #block-system-main {
        margin-top: 0;
    }

    #confirmation-body {
        @include font-family-standard;
        font-size: 16px;
        line-height: 24px;

        p {
            font-size: 19px;
            padding: 0px;
            color: $offblack;
        }

        .main-confirmation {
            @media screen and (min-width: $desktop) {
                width: 52%;
                float: left;
                .body-text {
                    margin-bottom: 29px;
                }
            }

            aside {
                @include side-block;
                @media screen and (min-width: $desktop) {
                    float: right;
                    position: absolute;
                    top: 11px;
                    left: 600px;
                }
                @media screen and (max-width: calc($desktop - 1px)) {
                    border: none;
                    padding-left: 0;
                    padding-right: 0;
                }

                @include confirmation-section
            }

            h2 {
                @include font-family-standard-bold;
                font-size: 28px;
                line-height: 33px;
                margin-bottom: 40px;
                margin-top: 40px;
            }
        }
    }

    section {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            @include font-family-standard-bold;
            font-size: 19px;
            margin-bottom: 0;
        }

        a h4 {
            text-decoration: underline;
        }
    }

    .container div.left-content {
      width: 100%;
    }

    #ready-to-do-more-confirmation {
      h2 {
        font-size: $size-3;
      }
      .store-section {
        background-color: $white;
        display: flex;
        .product-images {
          background-image: image-url('secadv-store@2x.jpg');
          width: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom right;
        }
        .header-followup {
          margin-bottom: 25px;
        }
        .left {
          padding: 40px;
          width: 380px;
          max-width: 100%;
          flex-shrink: 0;
        }
        a {
          @include font-family-standard-bold;
          font-size: 15px;
          color: $white;
          background-color: black;
          text-decoration: none;
          padding: 13px 40px;
        }
        a:hover,
        a:focus {
          color: $white;
          background-color: $grey-60;
          text-decoration: none;
        }
        h3 {
          @include font-family-compressed;
          font-size: $size-1;
          text-transform: uppercase;
          margin-top: 0px;
          margin-bottom: 25px;
        }
        .product-images-mobile {
          display: none;
        }
        @media screen and (max-width: calc($desktop - 1px)) {
          flex-direction: column;
          .header-followup {
            font-size: $size-6;
          }
          h2 {
            font-size: $size-3 !important;
          }
          h3 {
            margin-bottom: 10px;
            font-size: $size-2;
          }
          .left {
            padding: 24px;
          }
          .product-images-mobile {
            display: block;
          }
          .product-images {
            display: none;
          }
        }
      }
    }

    .header {
      section, .section {
        margin-bottom: 0;
      }
    }
    .sb_social_toolbox {
        margin-top: 20px;
        overflow: hidden;

        p {
            width: auto;
            margin: 0 10px 0 0;
            min-width: auto;
            float: left;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .sb_social_toolbox.sb_social_default_style a.social-share-link {
      @include sb-social-share-icons;
    }

    .webform-confirmation + .links {
        display: none;
    }

    #footer-webform {
        margin-top: 0;
        @media screen and (min-width: $tablet) {
            margin-top: 36px;
        }
    }

    .breadcrumb-nav {
        display:none;
        visibility: hidden;
    }

    .thankyou-page__callout{
        clear:both;
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        position: relative;
        right: 50%;
        width: 100vw;
    }

    @media screen and (min-width: $widescreen) {
        .page-title {
            max-width: 52%;
        }
    }
    @media screen and (max-width: calc($widescreen - 1px)) and (min-width: $tablet) {
        .page-title {
            max-width: 62%;
        }
    }
}
