#modalContent {
  @include font-family-standard;
  top: 100px !important;
  color: $offblack;
  width: 557px;
  max-width: 93%;
  @media screen and (max-width: 767px) {
    top: 40px !important;
  }

  .ctools-modal-content {
    border: none;
    width: 100% !important;
    height: auto !important;
  }

  #modal-content {
    width: auto !important;
    height: auto !important;
    padding: 0;
    overflow: visible;
  }

  #upsell-modal-title {
    @include font-family-compressed;
    padding: 35px 45px;
    font-size: $size-2;
    font-weight: $bold;
    line-height: 100%;
    color: $light-azure;
    #upsell-modal-subtitle {
      @include font-family-standard;
      margin-top: 30px;
      color: #fff;
      font-size: $size-5;
    }
    @media screen and (max-width: 767px) {
      font-size: $size-4;
      padding: 30px 20px;
    }
  }

  .upsell-thankyou,
  #upsell-modal-title {
    background-color: $dark-green;
  }

  .upsell-thankyou {
    #upsell-modal-content {
      @include confirmation-section;
      padding: 25px;
    }
    #upsell-modal-main-content {
      padding-bottom: 45px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
  }

  #upsell-modal-main-content {
    padding: 0 45px 20px;
    font-size: $size-7;
    .alert.error {
      border: none;
      color: $red-AA-compliant;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px 20px;
    }
  }

  .form-item label {
    float: none;
    width: auto;
    font-size: $size-7;
    font-weight: $bold;
  }

  .close-modal-wrapper {
    text-align: center;
  }

  .ctools-close-modal,
  .ctools-close-modal:hover {
    text-decoration: none;
    color: $offblack;
    font-weight: $bold;
  }

  #upsell-modal-amount {
    margin-bottom: 20px;
  }

  .form-item-amount {
    margin-top: 25px;
  }

  #upsell-modal-amount-wrapper {
    position: relative;
    font-size: 16px;
    .field-prefix {
      position: absolute;
      top: 14px;
      left: 17px;
      padding: 0;
      color: $offblack;
      font-weight: $bold;
    }
    [id^="edit-amount"] {
      height: 45px;
      width: 100%;
      padding-left: 40px;
      color: $offblack;
      font-weight: $bold;
      font-size: $size-6;
      border: 2px solid $offblack;
      margin-top: 3px;
      &.error {
        border-color: $red-AA-compliant;
      }
    }
  }

  [id^="edit-actions"] {
    padding: 0;
    margin-bottom: 0;
  }

  .accept-button {
    @include button-style2-primary;
    @include multistep-previous-next-button-size;
    &:hover {
        @include button-style2-primary-hover;
    }
    background-image: none;
    border-radius: 0;
  }

  .messages.error {
    border: none;
    @include validation-error;
    margin-top: 25px;
    padding-bottom: 0px;
    a span {
      font-weight: $bold;
    }
  }

  .btn:focus {
    outline-offset: 0px;
  }

  #modal-content > .close-modal-wrapper {
    background-color: transparent !important;
    opacity: 1 !important;
    top: -15px !important;
    right: -8px !important;
    position: absolute;
    margin: 0;
    padding: 0;
    a {
      @include close-button;
    }
  }

  .ajax-progress-throbber {
    display: flex;
    justify-content: space-around;
  }
}
