/* stylelint-disable no-duplicate-selectors  */
.node-type-donation-form.applepay-available {

  // Let's hide the Paypal option when Apple Pay is available.
  // We're doing this because we need to keep the applepayPaymentMethodRadio
  // radio selected in order to have 1-click Apple Pay donations. There's no good
  // way to keep it selected if a user bails on a Paypal payment.
  .pay-with-paypal {
    display: none !important;
  }
  &.step-0-active.onetime-donation-selected .multistep-donation.default-form-wrapper #edit-actions {
    display: block !important;
    @media screen and (max-width: calc($tablet - 1px)) {
      margin: 15px 0 0;
    }
  }
  &.monthly-donation-selected .multistep-donation.default-form-wrapper #edit-actions,
  &.annual-donation-selected .multistep-donation.default-form-wrapper #edit-actions {
    display: none;
  }
  #webform-component-donation.donation-buttons .form-item .form-type-radio.form-item.pay-with-applepay {
    display: none !important;
  }
  #edit-actions.form-actions {
    padding: 0;
  }
  .form-actions input#edit-submit.btn {
    @include button-style2-primary;
    @include multistep-previous-next-button-size;
    &:hover {
        @include button-style2-primary-hover;
    }
    color: transparent;
    margin: 0;
  }
  .form-actions input#edit-submit.btn {

    /* CSS from https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons */
    &.apple-pay-button {
      display: inline-block;
      background-size: 100% 41%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 0px;
      // removes extra gap when there are additional payment buttons below ApplePay
      &.stacked {
        margin-bottom: -15px;
      }
      @media screen and (min-width: $tablet) {
        margin-bottom: -15px;
      }
    }
    &.apple-pay-button-black {
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
    }
    &.apple-pay-button-white {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
    }
    &.apple-pay-button-white-with-line {
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-color: white;
      border: .5px solid black;
    }
  }

  #webform-component-donation.donation-buttons .form-item .form-type-radio.form-item.annual-wrapper,
  #webform-component-donation.donation-buttons .form-item .form-type-radio.form-item.monthly-wrapper {
    display: inline-block !important;
  }

  div.donation-processing-wrapper {
    width: 100%;
    .processing-spinner {
      margin-left: 50%;
    }
    p.processing-text {
      text-align: center;
    }
  }
}
