.node-type-donation-form .multistep-donation.default-form-wrapper {
  .donation-buttons-wrapper{
    position: relative;
  }
  .donation-steps{
    // hide until page is loaded to prevent flash
    visibility: hidden;

    @media screen and (min-width: 600px) {
      margin: 0 30px;
      //not an iframe
      position: absolute;
      z-index: 11;
      top: 26px;
      left: 0;
      width: calc(100% - 64px);
    }
  }
  .step-wrapper {
    line-height: 0;
    .breadcrumb-nav {
      ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      li {
        display: inline;
        margin-right: 1.5%;
        width: auto;
        line-height: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  /* Confirmation page. */
  .page-node-done .step-wrapper {
      display: none;
  }


    /* Show step indicators. */
  .step-wrapper .step {
      display: inline-block;
      background: $blue;
      width: 23.875%;
      height: 5px;
      text-align: center;
  }

  .step-wrapper .step:last-child {
      margin-right: 0;
  }

  /* Hide fieldsets and their headers initially. */
  .step-header {
      display: none !important;
      border: none;
      margin-top: 10px;
      float: left;
      width: 100%;
  }

  .step-header,
  fieldset legend {
      font-size: 19px;
      line-height: 22px;
  }

  /* Show active step. */
  .step-header.active {
      display: block !important;
  }

  .step-header,
  .node fieldset legend {
      background: white;
      padding: 0 0 5px 0;
      text-transform: none;
  }
  .step-header,
  legend[data-step] .fieldset-legend {
      position: relative;
      @include font-family-standard-bold;
  }

  .step-header span.visible-label:before,
  .node fieldset legend span.visible-label:before {
      color: white;
      background: $offblack;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      text-align: center;
      display: inline-block;
      margin-top: 3px;
      margin-right: 5px;
      font-weight: 200;
      font-size: 16px;
  }
  .node fieldset legend span.visible-label:before {
    content: attr(data-step);
  }

  .step-header span.visible-label:before,
  .node fieldset legend.step-header span.visible-label:before {
     content: attr(form-progress);
  }
}
body.step-0-active .multistep-donation.default-form-wrapper .step-wrapper{
  .step.step-1,
  .step.step-2,
  .step.step-3 {
    background: $grey-10;
  }
}
body.step-1-active .multistep-donation.default-form-wrapper .step-wrapper{
  .step.step-2,
  .step.step-3 {
    background: $grey-10;
  }
}
body.step-2-active .multistep-donation.default-form-wrapper .step-wrapper{
  .step.step-3 {
    background: $grey-10;
  }
}