.node-type-donation-form .multistep-donation.default-form-wrapper.multistep-donation-landing  {
	&.advocacy-donation-form {
		#header {
			border: none;
		}
		.footer-wrapper {
			margin-top: 0;
		}
		.section {
			padding-top: 0;
		}
		.container {
			height: 100%;
		}
		.page-title {
			display: none;
			margin-left: 1000px;
		}
		.field-name-body {
			font-family: $GTAM;
			font-size: 18px;
			line-height: 25px;
			h2 {
				font-size: 36px;
				line-height: 43px;
				text-transform: uppercase;
				font-family: "gtamcompressed", Arial, Helvetica, "Nimbus Sans L", sans-serif;
			}

			@media screen and (min-width: $desktop) {
				padding-right: 45px;
			}
		}

		div.content {
			#main-content {
				@media screen and (min-width: $desktop) {
					min-width: 350px;
				}
			}
			.webform-client-form {
				position: relative;
			}
		}

			.petition-confirmation-message {
				overflow: hidden;
				font-size: 0.8rem;
				line-height: 1.4;
				margin-bottom: 0;

				.status, .alert {
					background-color: transparent;
					color: $offblack;
					border: none;
					font-family: $GTAM;
					padding: 0;
					margin: 0;
					text-shadow: none;
					border-radius: 0;
					button {
						display: none;
					}
				}

				@media screen and (min-width: $tablet) {
					font-size: 1rem;
				}

				@media screen and (min-width: $desktop) {
					width: 440px;
					margin-bottom: 33px;
					min-height: 13px;
					font-size: 18px;
					line-height: 28px;
				}

				@media screen and (min-width: $widescreen) {
					width: 500px;
				}
			}
			.donation-landing-section {
				> div {
					padding-top: 0;
				}
			}
			#advocacy-social-share-block {
				background-color: $light-yellow;
				height: auto;
				/* Mobile */
				@media screen and (max-width: calc($tablet - 1px)) {
					margin-bottom: 32px;
				}
				.title {
					margin-top: 0;
					margin-bottom: 0;
					padding-top: 33px;
					padding-bottom: 33px;
					font-size: 45px;
					max-width: 550px;
					line-height: 51px;
					text-transform: uppercase;
					color: $offblack;

					@media screen and (max-width: calc($desktop - 1px)) {
						display: none;
					}
				}
				.share-content {
					color: $offblack;
					font-size: 21px;
					line-height: 28px;

					@media screen and (max-width: calc($desktop - 1px)){
						margin: 5px 0;
						max-width: none;
					}

					@media screen and (min-width: $desktop){
						max-width: 440px;
					}

					@media screen and (min-width: $widescreen) {
						max-width: 600px;
					}
				}
			}
		#advocacy-social-share-block, #webform-component-advocacy-social-share-block {
			.sb_social_default_style {
				width: auto;
				height: auto;
				margin-bottom: 43px;
				a:link,
				a:visited,
				a:hover,
				a:active {
				&.social-share-link {
					@include sb-social-share-icons(45px, 18px, 40px);
					width: 142px;
					border-radius: 0;
					text-decoration: none;
					text-align: left;
					display: inline-block;
					margin-right: 8px;
					img {
						margin: 13px 15px 15px 15px;
					}
					span {
						color: #fff;
						font-size: 16px;
						line-height: 19px;
						font-family: $GTAM;
						font-weight: bold;
						display: inline-block;
					}
					&.twitter {
						background-color: $twitter;
					}
					&.facebook {
						background-color: $facebook;
					}
					&.email {
						background-color: $email;
					}
					/* Mobile */
					@media screen and (max-width: calc($tablet - 1px)) {
						img {
							display: none;
						}
						text-align: center;
						max-width: 100px;
						margin-bottom: 5px;
						margin-right: 0px;
					}
				}
			}
			/* Mobile */
			@media screen and (max-width: calc($tablet - 1px)) {
				margin-bottom: 38px;
			}
			}
		}
	}
}

.main-site {
	overflow: hidden;
}

.node-type-donation-form .multistep-donation.default-form-wrapper .field-items {
	@media screen and (min-width: $desktop) {
		margin-top: 45px;
	}
}
