.node-type-donation-form .multistep-donation.default-form-wrapper {
    // Hide the browsers radio buttons
    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio],
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio],
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio],
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options input[type=radio] {
        border: 0;
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        display: inline-block;
        top: auto;
    }

    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options input[type=radio] {
        display: none;
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio],
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio],
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio] {
        clip-path: inset(50%);
        display: none;
    }

    // Amount and donation type buttons
    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio] + label,
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio] + label,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio] + label {
        @include button-select-toggle-unchecked;
        @include multistep-amount-type-button-size;
        .form-required {
            display: none;
        }
    }

    // traditional radio button look for donation type selector
    #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly {
        display: block;
        position: relative;

        #recurring-message-multistep {
            background-color: transparent;
            padding: 0 10px 0 0;
            font-size: 13px;
            line-height: 1.5em;
            font-weight: normal;
        }

        input[type=radio] {
            display: inline-block;
            height: auto;
            width: auto;
            clip-path: none;
            margin-top: 13px;
            position: absolute;
            // for ios clipping bug https://stackoverflow.com/a/55605561/6780626
            transform: translate(0px);

            & + label {
                display: block;
                height: auto;
                border-width: 0px;
                background-color: #fff;
                text-align: left;
                color: #000;
                padding: 10px 0;
                margin: 0 -30px;
                border-left: solid 60px #fff;
                border-right: solid 55px #fff;
                width: 536px;

                @media screen and (max-width: 1199px) {
                    width: 436px;
                }

                @media screen and (max-width: 999px) {
                    width: 506px;
                }

                @media screen and (max-width: 599px) {
                    width: 100vw;
                    border-right-width: 10px;
                }
            }

            &:checked + label {
                background-color: #E1F3F8;
                border-left-color: #E1F3F8;
                border-right-color: #E1F3F8;
            }
        }

        @media screen and (max-width: 599px) {
            .form-type-radio.form-item-submitted-donation-recurs-monthly{
                width: 100% !important;
            }

            .monthly-wrapper > .option {
                width: 100vw !important;
            }
        }
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio] + label:hover,
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio] + label:hover,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio] + label:hover {
        @include button-select-toggle-hover;
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio]:checked + label,
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio]:checked + label,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio]:checked + label {
        @include button-select-toggle-checked;
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount input[type=radio]:checked + label span,
    #webform-component-donation.donation-buttons #webform-component-donation--amount input[type=radio]:checked + label span,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount input[type=radio]:checked + label span {
        color: $white;
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount .form-item-frequencies-sb-fs-annually-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-recurring-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-amount .form-item-submitted-donation-recurring-amount,
    #edit-actions {
        margin: 0 $two-col-spacer $two-col-spacer 0;
        @media screen and (min-width: $tablet) {
          margin: 0 0 $two-col-spacer 0;
        }
    }

    #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly #edit-submitted-donation-recurs-monthly .form-item-submitted-donation-recurs-monthly {
      margin: 0 $two-col-spacer $two-col-spacer 0;
      @media screen and (min-width: $tablet) {
        margin: 0 0 calc($two-col-spacer - 10px) 0;
      }
    }

    #webform-component-donation.donation-buttons .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount .form-item-frequencies-sb-fs-annually-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-recurring-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-amount .form-item-submitted-donation-recurring-amount,
    #edit-submitted-extra-fields-payment-options .form-item-submitted-extra-fields-payment-options,
    #edit-actions {
        width: 100%;
        overflow: visible;
        flex: 1;
        &:last-child,
        &.other-button-wrapper {
            margin-right: 0%;
        }
        @media screen and (max-width: calc($tablet - 1px)) {
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(n + 3) {
                margin-top: 15px;
            }
            width: $two-col-width;
        }
    }
    #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly #edit-submitted-donation-recurs-monthly .form-item-submitted-donation-recurs-monthly {
      width: 100%;
      overflow: visible;
      flex: 1;
    }

    @media screen and (max-width: calc($tablet - 1px)) {
        #edit-submitted-extra-fields-payment-options .form-item-submitted-extra-fields-payment-options,
        #edit-actions {
            width: 100%;
        }
    }

    .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount .form-item,
    #webform-component-donation--amount .form-item,
    #webform-component-donation--recurs-monthly .form-item,
    #webform-component-donation--recurring-amount .form-item {
        display: inline-block;
    }

    // Next and payment method buttons
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options input[type=radio] + label.option,
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a #daf-link ,
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a #stock-link ,
    .progress-buttons button {
        @include button-style2-primary;
        @include multistep-previous-next-button-size;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            @include button-style2-primary-hover;
        }

        .info-tooltip-trigger {
          margin-top: 3px;
          margin-left: 5px;

          &:focus {
            outline: none;
          }
        }

        .info-tooltip-trigger:after {
          display: none;
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $light-slate;
          position: absolute;
          margin-top: -10px;
          -webkit-transform: translateX(50%);
          -ms-transform: translateX(50%);
          transform: translateX(calc(50% - 12px));
        }

        .info-tooltip-content {
          display: none;
          position: absolute;
          margin-top: -2px;
          width: 285px;
          background-color: $light-slate;
          color: $offblack;
          padding: 10px 10px 10px 15px;
          font-size: 15px;
          font-weight: bold;
          line-height: normal;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          white-space: normal;
          text-align: left;

          @media screen and (max-width: calc($tablet - 1px)) {
            -webkit-transform: translateX(-75%);
            -ms-transform: translateX(-75%);
            transform: translateX(-75%);
          }

          &.info-eft {
            width: 205px;
          }
        }


        .info-tooltip-trigger.info-tooltip-showing:after,
        .info-tooltip-trigger.info-tooltip-showing .info-tooltip-content {
          display: block !important;
        }
    }

    #edit-submitted-extra-fields-payment-options:not(.stacked) {
      row-gap: 15px !important;
    }

    .form-item-submitted-extra-fields-payment-options {
      min-width: $two-col-width;
    }

    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a  {
      text-decoration: none;
      display: inline-block;
      margin-top: 15px;

      width: 100%;
      @media screen and (min-width: $tablet) {
        margin-top: 0;
        width: $two-col-width;

        &.stacked {
          margin-top: 15px;
          width: 100%;
        }
      }
    }


    .progress-buttons button:last-child,
    #webform-component-extra-fields--payment-options .form-item-submitted-extra-fields-payment-options:last-child label {
        margin-bottom: 0px;
    }

    // Previous buttons
    .progress-buttons button.back {
        @include multistep-previous-next-button-size;
        @include button-style2-secondary;
    }

    .progress-buttons button.back:hover {
        @include button-style2-secondary-hover;
    }

    .progress-buttons {
        margin-top: 25px;
    }

    // Make the 'Processing' message look like a button
    .progress-buttons .processing {
        @include button-style2-primary;
        @include multistep-previous-next-button-size;
        @include button-style2-primary-hover;
        &:after {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            -webkit-animation: ellipsis steps(4,end) 1500ms infinite;
            animation: ellipsis steps(4,end) 1500ms infinite;
            content: "\2026"; /* ascii code for the ellipsis character */
            width: 0px;
            position: absolute;
            text-align: left;
        }
    }
}

@keyframes ellipsis {
    to {
      width: 1.2em;
    }
}
