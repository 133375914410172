$social-fb: #3460a1;
$social-twitter: #28aae1;
$social-email: #92d6e3;

// Primary Donation Form and Multistep Form Variables
// brands
$facebook: #3e5c98;
$twitter: #2fa4f0;
$youtube: #FF0000;
$instagram: #DF2E75;
$email: #000;

