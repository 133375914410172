.show-donation-amount-texts {
    #webform-component-donation--amount,
    #webform-component-donation--recurring-amount {
        // up arrows above text
        @media only screen and (min-width: 600px) {
            #edit-submitted-donation-recurring-amount .active label,
            #edit-submitted-donation-amount .active label {
                position: relative;
                &::before {
                    content: " ";
                    position: absolute;
                    top: 46px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: 0;
                    height: 0;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid $light-azure;
                }
            }
        }
        .donation-amount-text-wrapper {
            .donation-amount-text {
                background-color: $light-azure;
                font-size: 13px;
                padding: 7px 12px;
                margin: 5px 0 30px;
                display: none;
                position: relative;

                &.active {
                    display: block;
                }
            }
        }
    }
}


// In the iframe, always show the arrows
.multistep-donation-landing--iframe {
    .show-donation-amount-texts {
        #webform-component-donation--amount,
        #webform-component-donation--recurring-amount {
            // up arrows above text
            #edit-submitted-donation-recurring-amount .active label,
            #edit-submitted-donation-amount .active label {
                position: relative;
                &::before {
                    content: " ";
                    position: absolute;
                    top: 46px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: 0;
                    height: 0;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid $light-azure;
                }
            }
        }
    }
}


// style overrides for redistricting main donate form
.page-node-68871, .page-node-70609 .show-donation-amount-texts {
    #webform-component-donation--amount,
    #webform-component-donation--recurring-amount {
        .donation-amount-text-wrapper .donation-amount-text {
            &::before {
                // hide up arrows when donate buttons are stacked
                @media (max-width: 599px) {
                    display: none;
                }
            }

            // adjust up arrow positions for different form widths
            &:nth-child(1)::before {
                @media (max-width: 1199px) {
                    left: 30px;
                }
            }

            &:nth-child(2)::before {
                left: 145px;
                @media (min-width: 1000px) {
                    left: 125px;
                }
                @media (min-width: 1200px) {
                    left: 155px;
                }
            }

            &:nth-child(3)::before {
                left: 260px;
                @media (min-width: 1000px) {
                    left: 220px;
                }
                @media (min-width: 1200px) {
                    left: 280px;
                }
            }
        }
    }
}
