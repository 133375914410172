// Fancy checkboxes
.webform-component-checkboxes .form-type-checkbox,
.form-type-checkbox.form-item-submitted-payment-information-payment-fields-credit-ocd {
  position: relative;
  margin-bottom: 0;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    & + label:before {
      content: " ";
      // inlined version of https://static.aclu.org/images/icon/bold_black_checkbox.png
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAMAAADzNpNpAAAAM1BMVEUjHyBaV1iRj5BMSUp1cnP///92c3Tx8fE/OzyRj4/Ix8esq6toZWYxLS7j4+OtrKwAAABdeiM4AAAAEXRSTlP/////////////////////ACWtmWIAAACBSURBVHgB7cu1AQMBFEOxY6b9lw2Dw+9XIatXYvYB0iwPjqIsyyI89NChh46yTMOjrMKjbsIjT+5quzY4+rIs++jQw4YeMIbyqIcjqUo9ZCTjJIeMjUYOG3ro0KODHx386GCHDT1wyIFDDhxy2JAz93SYGbBgLi4uLi6Ei4vLj1gDHd92cMItvegAAAAASUVORK5CYII=) no-repeat;
      background-size: 25px 50px;
      top: 3px;
      height: 25px;
      width: 25px;
      border: none;
      background-position: 0px -25px;
      margin-right: 7px;
      position: absolute;
      left: 0px;
    }
    &:checked + label:before {
        background-position: 0 0px;
    }
  }
  label {
    margin: 0;
    padding-left: 43px;
    width: 100%;
    padding-top: 0;
    box-sizing: border-box;
    display: inline-block;
  }
}
