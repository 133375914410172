@import "../default-form/form-captcha";

.node-type-donation-form .multistep-donation.default-form-wrapper .captcha.form-wrapper {

  margin-top: 30px;

  .reminder-display {
    margin-bottom: 10px;
  }

  .description {
    display: block !important;
  }

  #edit-captcha-response {
    margin-bottom: 10px;

  }

  .form-item-captcha-response {
    margin-top: 10px;
  }

}

@media screen and (min-width: $desktop) {
  .captcha-page .field-type-text-with-summary {
    min-height: 550px;
  }
}
