.node-type-donation-form .multistep-donation.default-form-wrapper {
    // on desktop - always display desktop content
    @media (min-width: $desktop) {
        .body-mobile {
            display: none;
        }
        .body-nonmobile {
            display: block;
        }
    }

    // on mobile/tablet - don't dislay mobile or desktop content
    @media (max-width: calc($desktop - 1px)) {
        .body-nonmobile,
        .body-mobile {
            display: none;
        }
    }
}

// special rules for step 1 on mobile/tablet
.node-type-donation-form.step-0-active .multistep-donation.default-form-wrapper {
    // on mobile - display mobile content
    @media (max-width: calc($tablet - 1px)) {
        .body-mobile {
            display: block;
        }
    }

    // on tablet - display desktop content
    @media (min-width: $tablet) and (max-width: calc($desktop - 1px)) {
        .body-nonmobile {
            display: block;
        }
    }
}
