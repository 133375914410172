@mixin button-style1-general {
  @include font-family-standard-bold;
  text-align: center;
  border-radius: 0px;
  font-size: 16px;
  line-height: 19px;
  box-sizing: border-box;
}

// Select/toggle buttons (ie block radio buttons)
@mixin button-select-toggle-unchecked {
  @include button-style1-general;
  background-color: $white;
  color: black;
  border: 2px solid black;
}

@mixin button-select-toggle-checked {
  background-color: black;
  color: $white;
  border-color: black;
}

@mixin button-select-toggle-hover {
  background-color: $grey-60;
  color: $white;
  border-color: $grey-60;
}

// Style 2 Global
@mixin button-style2-general {
  @include font-family-standard-bold;
  font-size: 16px;
  padding: 0;
  text-transform: none;
  text-align: center;
  text-shadow: none;
  border: none;
}

// Style2 Primary
@mixin button-style2-primary {
  @include button-style2-general;
  color: $white;
  background-color: black;
}
@mixin button-style2-primary-hover {
  background-color: $grey-60;
}

// Style2 Secondary
@mixin button-style2-secondary {
  @include button-style2-general;
  color: black;
  background-color: $white;
  border: 2px solid black;
  line-height: 40px;
}
@mixin button-style2-secondary-hover {
  color: $grey-60;
  border: 2px solid $grey-60;
}

// Multistep-specific button styles
@mixin multistep-amount-type-button-size {
  width: 100%;
  height: 45px;
  line-height: 41px;
  padding: 0 10px;
  margin: 0;
  display: inline-block;
}

@mixin multistep-previous-next-button-size {
  width: 100%;
  height: 44px;
  line-height: 44px;
  margin: 0 0 $spacer 0;
  &:last-child {
    margin-bottom: 0px;
  }
}

// Multistep placeholder style
@mixin multistep-placeholder {
  @include font-family-standard-bold;
  color: #595959;
  font-size: 16px;
  font-weight: bold;
  opacity: 1; // Firefox 
  line-height: -moz-block-height; // Firefox
}

@mixin side-block {
  border: 2px solid $grey-20;
  box-sizing: border-box;
  padding: 30px;
  overflow: hidden;
  margin-bottom: 0px;
}

@mixin disclaimer-style1-text {
  font-size: 13px;
  line-height: 1.5em;
}

@mixin confirmation-section {
  background-color: white;
  h2 {
    @include font-family-standard-bold;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 7px;
    margin-top: 0;
  }
  .confirmation-number {
    font-size: 28px;
    line-height: 36px;
    color: $blue;
    margin-bottom: 20px;
  }
  p:last-child {
    font-size: $size-5;
    margin-bottom: 0;
  }
}
