.node-type-donation-form .multistep-donation.default-form-wrapper.multistep-donation-landing--iframe{
  .action-links,
  .field-name-body,
  .field-name-field-aclu-summary{
    display: none;
  }

  .message-wrapper .error {
    border-radius: 0;
  }

  #innerwrapper{
    width: 100%;
  }
  .container{
    padding: 0 30px;
  }
  .section{
    padding: 30px 0;
  }
  #block-system-main{
    margin-top: 0;
  }
  .fundraiser-donation-form{
    width: 100%;
    position: relative;
    float: none;
  }

  .donation-steps{
    @media screen and (min-width: 600px) {
      position: relative;
      z-index: 11;
      top: initial;
      left: initial;
      width: auto;
    }
  }
  fieldset.fieldset-step{
    border: none;
    margin-top: 0;
    padding-top: 0;
  }
  //leave buttons side-by-side
  #webform-component-donation--other-amount label,
  #webform-component-donation--recurring-other-amount label,
  #edit-submitted-donation-recurring-amount,
  #edit-submitted-donation-amount,
  #edit-frequencies-sb-fs-annually-amount,
  #edit-submitted-donation-recurs-monthly,
  #edit-submitted-extra-fields-payment-options {
    @media screen and (min-width: $iframeMobileWidth) and (max-width: calc($tablet - 1px)) {
      display: flex;
      flex-flow: row wrap;
    }
  }
  #edit-submitted-extra-fields-payment-options {
    padding-bottom: 30px;
  }
  #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-message {
    padding: 0;
  }
  .progress-wrap--monthly {
    #edit-submitted-extra-fields-payment-options {
      padding-bottom: 15px;
    }
    .form-item-submitted-extra-fields-payment-options {
      margin-right: 0;
    }
  }

  #edit-submitted-extra-fields-payment-options .form-item-submitted-extra-fields-payment-options{
    margin-bottom: 0;
  }

  // keep side-by-side buttons for mobile
  .step-1 .progress-wrap,
  .step-2 .progress-wrap,
  .step-3 .progress-wrap{
    @media screen and (min-width: $iframeMobileWidth) and (max-width: 767px) {
      display: inline-flex;
      .back {
        margin-right: 15px;
        width: auto;
      }
    }

  }

  // Keep name fields side-by-side above mobile width
  @media screen and (min-width: $iframeMobileWidth) and (max-width: 767px) {
    #webform-component-donor-information--first-name,
    #webform-component-donor-information--last-name{
      display: inline-block;
      width: calc(50% - 7.5px);
      vertical-align: top;
    }
    #webform-component-donor-information--first-name{
      margin-right: 15px;
    }
  }

  //Fix outer padding
  .fieldset-step,
  #webform-component-donation.donation-buttons.fieldset-step{
    margin-top: 0;
  }
  .webform-client-form{
    margin-top: 0;
    margin-bottom: 0;
  }

  .progress-buttons button,
  .progress-buttons .processing {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 30px;
    }
  }

  // overrides for buttons
  #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-recurring-amount,
  #webform-component-donation.donation-buttons #webform-component-donation--amount #edit-submitted-donation-amount .form-item-submitted-donation-amount,
  #webform-component-donation.donation-buttons #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-amount .form-item-submitted-donation-recurring-amount,
  #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly #edit-submitted-donation-recurs-monthly .form-item-submitted-donation-recurs-monthly,
  #webform-component-donation.donation-buttons #edit-frequencies-sb-fs-annually-amount .form-item-frequencies-sb-fs-annually-amount,
  #edit-submitted-extra-fields-payment-options .form-item-submitted-extra-fields-payment-options,
  #edit-actions {
    &:last-child,
    &.other-button-wrapper {
      margin-right: 0% !important;
    }
    @media screen and (min-width: $iframeMobileWidth) and (max-width: calc($tablet - 1px)) {
      margin: 0 0 $two-col-spacer 0;
      width: 100%;
      &:nth-child(n + 3) {
        margin-top: 0;
    }
    }
  }
  #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly #edit-submitted-donation-recurs-monthly .form-item-submitted-donation-recurs-monthly:nth-child(2n){
    margin-right: 0;
  }
}

// fix outer padding on confirmation screen
.page-node-done .multistep-donation.default-form-wrapper.confirmation.multistep-donation-landing--iframe{
  #confirmation-body .main-confirmation aside{
    padding-left: 30px;
    padding-right: 30px;
  }
  .region.region-content{
    font-size: 0;
  }
}

// keep review terms button same height as previous button when side-by-side
button.review-terms {
  @media screen and (min-width: calc($iframeMobileWidth)) {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: calc($iframeMobileWidth - 1px)) {
  // stack credit card exp month/year on mobile
  .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month div.select {
    float: none !important;
    width: 100% !important;
  }
  .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month div.select .custom-select-container {
    margin-bottom: 25px;
  }
  #edit-submitted-payment-information-payment-fields-credit-card-number {
    margin-bottom: 0 !important;
  }
  // stack T&C agree/disagree buttons on mobile
  .terms-buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: 0 !important;
    & button {
      flex: none !important;
    }
  }
  // fixes width on narrow screens on bank account info step
  .sample-check img {
    max-width: 100% !important;
    max-height: 165px;
  }
}