body {
  padding: 0;
}
.control-group {
  margin-bottom: 0px;
}

.form-actions {
  background: none;
  border-top: none;
}
