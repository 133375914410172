body {
    // Thermometer styles
  .wg-goal-autodisappearing.therm-style2,
  .wg-goal.therm-style2 {
    margin: 7px 0px;
    font-size: 18px;
    @include font-family-standard-bold;
    &,
    p {
      color: $offblack;
    }
    .light {
      font-weight: normal;
    }
    .wg-progress {
      width: 100%;
      border: none;
      background: $grey-10 !important;
      padding: 0px;
      margin-top: 0px;
      border-radius: 10px !important;
      height: 20px !important;
      margin-bottom: 14px;
      .bar {
        background: #691B40 !important;
        min-width: auto;
        &:after {
          content: none;
        }
      }
    }
  }

  .therm-style3 {
    .wg-progress {
      height: 29px !important;
      border: 4px solid #f9b3b8 !important;
      background: #f9b3b8 !important;
    }
    .bar {
      background-color: #EF404E !important;
      height: 21px;
    }
    .goal-text {
      font-family: $aclu-sans-compressed;
      font-size: 36px;
      text-transform: uppercase;
      padding-bottom: 2px;
      color: $dark-navy;
    }
    .goal-text strong {
      font-family: $aclu-sans-compressed;
    }
    .goal-match-text {
      font-size: 21px;
      padding-top: 20px;
    }
    @media screen and (max-width: 999px) {
      padding-bottom: 7px;
      .goal-text {
        font-size: 24px;
        padding-bottom: 6px;
      }
      .goal-match-text {
        display: none;
      }
    }
  }

  .therm-style4 {
    margin-top: 45px;
    display: inline-block;
    .wg-progress {
      height: 29px !important;
      border: 4px solid #BFD4EA !important;
      background: #BFD4EA !important;
    }
    .bar {
      background-color: #0055AA !important;
      height: 21px;
    }
    .goal-text {
      font-family: $aclu-sans-compressed;
      font-size: 36px;
      text-transform: uppercase;
      padding-bottom: 2px;
      text-align: right;
      padding-right: 5px;
      strong {
        font-family: $aclu-sans-compressed;
      }
    }
    .goal-match-text {
      font-family: $aclu-sans-compressed;
      font-size: 1.45em;
      padding: 13px;
      color: #fff;
      background-color: #000000;
      text-transform: uppercase;
      width: 100%;
      height: 57px;
      display: inline-block;
      margin-top: 25px;
      line-height: 23px;
      text-align: center;
      strong {
        font-family: $aclu-sans-compressed;
      }
    }
    @media screen and (max-width: 999px) {
      margin-top: 25px;
      .goal-text {
        font-size: 24px;
        padding-bottom: 6px;
      }
    }
    @media screen and (max-width: 450px) {
      .goal-match-text {
        font-size: 93%;
        line-height: 1;
        height: auto;
      }
    }
  }
}
