// It'd be nice to get rid of the styles in this file
// if we ever import the full bulma styles.

// General rebrand styles
body {
  word-spacing: .0625rem;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
  
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.container {
  margin: 0 auto;
  position: relative;
}

.section {
  padding: 15px 0;
}

.has-background-white {
  background-color: #fff!important;
}