// MIXINS
@mixin font-family-compressed () {
  font-family: $aclu-sans-compressed;
  line-height: 1;
  letter-spacing: 0.005rem;
  word-spacing: 0.1rem;
  font-weight: $bold;
}
@mixin font-family-standard () {
  font-family: $aclu-sans-standard;
  line-height: 1.4;
  letter-spacing: -0.005rem;
  word-spacing: 0.0625rem;
  font-weight: $normal;
}
@mixin font-family-standard-bold () {
  font-family: $aclu-sans-standard;
  line-height: 1.4;
  letter-spacing: 0rem;
  word-spacing: 0.0625rem;
  font-weight: $bold;
}
@mixin font-family-serif () {
  font-family: $aclu-serif;
  line-height: 1.4;
  letter-spacing: 0.01rem;
  word-spacing: -0.12rem;
}
@mixin section-title () {
  @include font-family-compressed;
  font-size: $size-2;
  text-transform: uppercase;
  color: $offblack;
}
@mixin cta-card () {
  display: block;
  text-decoration: none;
  color: $text;

  &:hover {
    .cta {
      opacity: $hover-opacity;
    }
  }
}

@mixin validation-error () {
  @include font-family-standard;
  border-top: 2px solid $red-AA-compliant;
  padding: 5px 0 20px 0;
  margin: 0;
  font-size: 16px;
  line-height: 23px;
  color: $offblack;

  .error-intro {
    color: $red-AA-compliant;
    line-height: 22px;
    font-size: 16px;
    @include font-family-standard-bold;
  }

  a {
    color: $offblack;
    &:hover,
    &:focus {
      color: $grey-60;
    }
  }

  ul {
    padding-left: 16px;
    margin: 5px 0 0 0;

    li {
      font-size: 16px;
      line-height: 23px;
    }
  }

  button {
    display: none;
  }
}

@mixin close-button () {
  display: block;
  height: 49px;
  min-width: 49px;
  border: 2px solid #000;
  background-color: $white;
  border-radius: 50%;
  box-sizing: border-box;
  &::before,
  &::after {
    transform: rotate(45deg);
    background-color: #000;
    content: "";
    width: 15px;
    height: 3px;
    position: absolute;
    top: 23px;
    left: 17px;
  }
  &::after {
    transform: rotate(-45deg);
  }
}
