/* stylelint-disable no-duplicate-selectors  */
.node-type-donation-form .multistep-donation.default-form-wrapper {

    #webform-component-donation.tooltip-enabled {
      overflow: visible;
    }

    .page-title,
    fieldset > div {
        width: 100%;
    }

    .page-title h1 {
        margin-bottom: 30px;

        @media screen and (max-width: calc($tablet - 1px)) {
            margin-bottom: 25px;
        }
    }

    // body text
    .field-items {
        margin-top: 45px;

        @media screen and (max-width: calc($tablet - 1px)) {
            margin-top: 25px;
        }
    }

    .page-subtitle h2 {
        margin-top: 30px;

        @media screen and (min-width: $tablet) {
            margin-bottom: -15px;
        }

        .field-items {
            margin-top: 0px;
        }
    }

    #wrapper {
        padding-bottom: 0;
    }

    .field-name-body.right-content.field-type-text-with-summary {
        width: 440px;
        top: -11px;
        position: relative;
        float: left;
        .field-items {
          margin-bottom: 30px;
        }
    }

    #webform-component-c3-disclaimer p.disclaimer,
    #webform-component-c4-disclaimer p.disclaimer {
      margin: 30px 0 30px 0;
    }

    #webform-component-other-ways-to-give {
      h3,
      h3, .is-donate-link {
        @include font-family-compressed;
        text-transform: uppercase;
        text-decoration: none;
        color: #231F20;
      }
    }

    .webform-client-form {
        // hide until page is loaded to prevent flash
        visibility: hidden;
        float: right;
        clear: none;
    }

    div.content {
        margin-top: 0px;
    }

    .stepContentWrapper {
        clear: both;
    }

    .fundraiser-donation-form {
        padding: 0px;
        display: inline-block;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="search"]{
      padding: 5px 10px;
      font-size: 18px;
    }

    .currency-in-input input#edit-submitted-donation-other-amount,
    .currency-in-input input#edit-submitted-donation-recurring-other-amount,
    .node input,
    .control-group.success input,
    .control-group.success textarea,
    .form-item input[type="text"],
    .form-item input[type="email"] {
        border: 2px solid black;
        float: none;
        display: block;
        height: 60px;
        padding-top: 18px;
        margin-bottom: 25px;
    }

    #edit-submitted-payment-information-payment-fields-credit-card-number {
        margin-bottom: 25px;
    }

    .form-item label,
    .payment-method-message {
        @include font-family-standard-bold;
        font-size: 13px;
        line-height: 1.5em;
        width: 100%;
        margin: 25px 0 15px;
    }

    .form-item.form-type-checkbox label {
        margin: 0;
    }

    .form-item-frequencies-sb-fs-annually-amount label,
    #webform-component-donation--recurring-amount label,
    #webform-component-donation--amount label {
        margin-top: 15px;
    }
    #webform-component-donation--recurring-other-amount label,
    #webform-component-donation--other-amount label {
        margin-top: 0;
    }

    // hide general CC expiration label since we add in more specific ones
    .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month > label {
        display: none;
    }

    .webform-component-textfield,
    .webform-component-email,
    .select,
    .form-type-textfield,
    .form-type-select {
        position: relative;
        label {
            position: absolute;
            top: 8px;
            left: 12px;
            margin: 0;
            padding: 0;
            z-index: 1;
        }
    }

    .fieldset-step > nav h3 {
        padding-bottom: 0;
    }

    fieldset.fieldset-step {
        @include side-block;
    }

    .reminder-display {
        @include font-family-standard-bold;
        font-size: 19px;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: $offblack;
    }

    .form-item-submitted-payment-information-payment-fields-credit-card-number,
    .form-item-submitted-payment-information-payment-fields-credit-card-cvv,
    #webform-component-donation.donation-buttons #webform-component-donation--recurs-monthly,
    #webform-component-donation--amount,
    #webform-component-donation--recurs-monthly .form-item,
    div.webform-component,
    .form-type-checkbox {
        overflow: visible;
    }

    div.form-item.form-type-radio.form-item-submitted-extra-fields-payment-options:focus,
    div.form-item.form-type-radio.form-item-submitted-extra-fields-payment-options:active,
    input[type=radio]:focus + label,
    #edit-frequencies-sb-fs-annually-amount-other:focus
    #edit-submitted-donation-other-amount:focus,
    #edit-submitted-donation-recurring-other-amount:focus,
    .webform-component-checkboxes input[type="checkbox"]:focus,
    input:focus,
    .webform-component-checkboxes input[type="checkbox"]:focus + label:before,
    .progress-wrap button:focus,
    .form-item select:focus ~ .custom-select {
        outline: 2px solid #92d6e3;
        box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.075), 0 0 2px 2px rgba(82, 168, 236, 0.6);
    }


    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a:focus,
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a:active {
      outline-offset: 0;
      outline: 2px solid #92d6e3;
    }

    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a:focus #daf-link,
    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-options a:active #daf-link {
      box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.075), 0 0 2px 2px rgba(82, 168, 236, 0.6);
    }

    #webform-component-donation--amount #webform-component-donation--other-amount,
    #webform-component-donation--recurring-amount #webform-component-donation--recurring-other-amount,
    #webform-component-donation.donation-buttons #webform-component-donation--other-amount input[type="text"],
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-other-amount input[type="text"] {
        width: 100%;
        max-width: inherit;
        float: none;
    }

    // Hide by default at desktop and mobile size
    #webform-component-donation--other-amount.form-item.other-amount-wrapper, 
    #webform-component-donation #webform-component-donation--recurring-other-amount.form-item.recurring-other-amount-wrapper,
    #webform-component-donation #webform-component-donation--amount.form-item.other-amount-wrapper {
        display: none;
    }

    #webform-component-donation.donation-buttons #webform-component-donation--other-amount input[type=text],
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-other-amount input[type=text],
    .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount-other,
    #webform-component-donation--amount #edit-submitted-donation-other-amount,
    #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-other-amount {
        text-align: left;
        color: $offblack;
        @include font-family-standard-bold;
        font-size: 16px;
    }

    .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount-other::placeholder,
    .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount-other:-ms-input-placeholder,
    .form-item-frequencies-sb-fs-annually-amount #edit-frequencies-sb-fs-annually-amount-other::-ms-input-placeholder,
    #webform-component-donation--amount #edit-submitted-donation-other-amount::placeholder,
    #webform-component-donation--amount #edit-submitted-donation-other-amount:-ms-input-placeholder,
    #webform-component-donation--amount #edit-submitted-donation-other-amount::-ms-input-placeholder,
    #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-other-amount::placeholder,
    #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-other-amount:-ms-input-placeholder,
    #webform-component-donation--recurring-amount #edit-submitted-donation-recurring-other-amount::-ms-input-placeholder {
        @include multistep-placeholder;
    }

    .form-item .currency-in-input {
        display: block;
        border: none;
    }

    #webform-component-donation--amount #webform-component-donation--other-amount,
    #webform-component-donation--recurring-amount #webform-component-donation--recurring-other-amount {
        position: relative;
    }

    #webform-component-donation--other-amount .prefix-span-wrapper,
    #webform-component-donation--recurring-other-amount .prefix-span-wrapper {
        position: relative;
        margin-top: 0;
        width: auto;
        z-index: 300;
    }

    #webform-component-donation.donation-buttons #webform-component-donation--recurring-other-amount .prefix-span-wrapper .field-prefix,
    #webform-component-donation.donation-buttons #webform-component-donation--other-amount .prefix-span-wrapper .field-prefix {
        @include font-family-standard-bold;
        display: block;
        font-size: 16px;
        position: absolute;
        left: 11px;
        top: 30px;
    }

    .other-amount-wrapper .currency-in-input input#edit-submitted-donation-other-amount,
    .recurring-other-amount-wrapper .currency-in-input input#edit-submitted-donation-recurring-other-amount {
        padding-left: 23px;
        padding-right: 23px;
        padding-top: 25px;
    }

    #webform-component-donation--other-amount label,
    #webform-component-donation--recurring-other-amount label,
    #edit-frequencies-sb-fs-annually-amount,
    #edit-submitted-donation-recurring-amount,
    #edit-submitted-donation-amount,
    #edit-submitted-extra-fields-payment-options {
        display: flex;
        flex-flow: row wrap;
        column-gap: $two-col-spacer;

        &.stacked {
          flex-flow: column;

          div {
            margin-left: 0;

            &:not(:first-of-type) {
              margin-top: 15px;
            }
          }
        }
        @media screen and (max-width: calc($tablet - 1px)) {
            display: block;
        }
    }

    #edit-submitted-extra-fields-payment-options {
        width: 100%;
    }

    #edit-submitted-extra-fields-payment-options .form-item-submitted-extra-fields-payment-options {
        width: 45%;
        margin-top: 0;
        overflow: visible;
        display: inline-block;
        height: 100%;
        &.pay-with-bank::after {
          content: '*US Bank Accounts Only';
          text-align: center;
          font-size: 13px;
          padding-top: 15px;
          display: block;
          width: 100%;
        }
        /* Used to hide the 'US Bank Accounts Only' message
         * when the tooltip with the same message is included
         */
        &.pay-with-bank.pay-with-bank-tooltip::after {
          display:none;
        }
    }

    #webform-component-donation--recurs-monthly {
        margin-top: 0px;
        .form-item {
            width: 50%;
            margin: 0;
        }
    }

    .fieldset-wrapper .webform-component-checkboxes label.option {
        @include font-family-standard;
        @include disclaimer-style1-text;
    }

    #webform-component-donation #webform-component-donation--other-amount.form-item,
    #webform-component-donation #webform-component-donation--recurring-other-amount.form-item,
    #webform-component-extra-fields--payment-options {
        overflow: visible;
        margin-bottom: 0px;
    }

    #webform-component-extra-fields--payment-options {
        display: block !important;
    }

    .expiration-date-wrapper {
        overflow: hidden;
    }

    .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .auto-size,
    .form-item-submitted-payment-information-payment-fields-credit-card-cvv {
        width: $two-col-width;
    }

    .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .auto-size:first-of-type {
        float: left;
    }

    .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .auto-size:last-of-type {
        float: right;
    }


    .form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .custom-select-container.auto-size {
        width: 100%;
    }

    #edit-submitted-billing-information-country,
    .form-item select,
    .form-item select + span {
        min-width: 100px;
    }

    #webform-component-donation--email-opt-in {
        margin-top: 10px;
    }

    .control-group.success input {
        color: $offblack;
    }

    #edit-submitted-payment-information-payment-fields-credit,
    #edit-submitted-payment-information-payment-fields-credit {
        display: none !important;
    }

    #edit-submitted-payment-information-payment-fields-paypal {
        margin-top: 0;
    }

    .field.field-name-field-aclu-right-column.field-type-text-long.field-label-hidden {
        display: none;
    }

    /* Hide form submit. */
    #edit-actions {
        display: none !important;
    }

    /* Hide fieldsets and their headers initially. */
    #webform-component-donation,
    #webform-component-donor-information,
    #webform-component-billing-information,
    #webform-component-payment-information {
        display: none;
    }

    /* Hide inactive steps. */
    .fieldset-step {
        display: none !important;
    }

    /* Show active step. */
    .fieldset-step.active {
        display: block !important;
    }

    /* Display active step fields. */
    .fieldset-step.active #webform-component-donation,
    .fieldset-step.active #webform-component-donor-information,
    .fieldset-step.active #webform-component-billing-information,
    .fieldset-step.active #webform-component-payment-information {
        display: block;
    }

    #webform-component-payment-information #webform-component-payment-information--payment-method {
        display: none;
    }

    #webform-component-donation.donation-buttons #webform-component-donation--other-amount .prefix-span-wrapper .prefix-text,
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-other-amount .prefix-span-wrapper .prefix-text {
        display: none;
    }

    .form-item .currency-in-input .symbol {
        display: none;
    }

    #webform-component-donation--recurring-other-amount.form-item label,
    #webform-component-donation--other-amount.form-item label,
    #webform-component-donation.donation-buttons #webform-component-donation--other-amount input[type="text"],
    #webform-component-donation.donation-buttons #webform-component-donation--recurring-other-amount input[type="text"] {
        float: none;
    }

    #webform-component-donation.donation-buttons #webform-component-extra-fields {
        display: none;
    }

    #webform-component-donation.donation-buttons #webform-component-extra-fields--payment-message {
        @include font-family-standard;
        font-size: 13px;
        line-height: 1.5em;
        width: 100%;
        padding: 14px 0 0 0;
        margin: 0 0 30px 0;
        text-align: center;
        p {
            margin: 0;
        }
    }

    @media screen and (max-width: calc($tablet - 1px)) {
      .form-item-submitted-extra-fields-payment-options + .form-item-submitted-extra-fields-payment-options {
        margin-left: 0;
      }
    }

    p.donation_disclaimer {
        padding: 1rem 0 0 0;
        margin-bottom: 0;
    }
    p.donation_disclaimer:empty {
      padding: 8px;
      @media screen and (min-width: $tablet) {
        padding: 0;
      }
    }
    &.multistep-donation-landing {

        @media screen and (min-width: $desktop) {
            .node-donation-form > div.content {
                position: relative;
                .webform-client-form {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .fieldset-step {
        position: relative;
        background-color: $white;
        z-index: 10;
    }

    div.donation-processing-wrapper {
        padding: 0;
    }

    .processing-fee-message {
      display: none;
    }

    /* Mobile display. */
    @media screen and (max-width: calc($tablet - 1px)) {
        fieldset.fieldset-step {
            border: none;
            padding: 0;
        }

        .webform-client-form {
            float: none;
        }

        .fundraiser-donation-form {
            width: 100%;
            display: block;
        }
    }

    .fundraiser_submit_message {
        display: none !important;
    }

    .form-required-info {
        font-size: $size-7;
    }

    .help-text {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip-path: inset(50%);
    }

    .description {
      display: none !important;
    }

    .metrix-container, .metrix-container-cc {
        display: none;
    }

    /* Tablet display. */
    @media screen and (max-width: calc($desktop - 1px)) {

        .form-item label.option {
            float: none;
        }

        fieldset.fieldset-step {
            overflow: visible;
        }

        .webform-client-form {
            display: block;
        }

        #webform-component-donation.donation-buttons legend {
            display: block;
        }

        .node-donation-form .content .field-name-field-aclu-summary {
            display: none;
        }

        .field-name-body.right-content.field-type-text-with-summary {
            width: 100%;
            display: block;
            float: none;
            margin-right: 0px;
        }

        .fundraiser-donation-form {
            width: 100%;
        }

        .form-item .currency-in-input {
            margin-top: 0;
        }
    }
}

.multistep-donation.default-form-wrapper.multistep-donation-landing:not(.confirmation) .donation-landing-section.desktop-only {
  @media screen and (max-width: 768px) {
    &.has-background-yellow {
      background-color: #fff;
    }
    .has-section-padding {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.multistep-donation.default-form-wrapper.multistep-donation-landing {

    /* Desktop display. */
    @media screen and (min-width: $desktop) and (max-width: calc($widescreen - 1px)) {
        .field-name-body.right-content.field-type-text-with-summary,
        .donation-landing-section .container .left-content {
            width: 440px;
        }
        .fundraiser-donation-form {
            width: 440px;

            &.stacked {
              width: 464px;
            }
        }
        .show-mobile-only {
          display: none;
        }
    }

    /* Wide desktop display. */
    @media screen and (min-width: $widescreen) {
        .field-name-body.right-content.field-type-text-with-summary,
        .donation-landing-section .container .left-content {
            width: 550px;
        }
        .fundraiser-donation-form {
            width: 540px;

            &.stacked {
              width: 464px;
            }
        }
        .show-mobile-only {
          display: none;
        }
    }

    .donation-landing-section {
        &.has-background-yellow {
          background-color: $light-yellow;
        }
        &.has-background-white {
          background-color: #fff;
        }
        .has-section-padding  {
            padding-top: 45px;
            padding-bottom: 45px;
            @media screen and (max-width: calc($tablet - 1px)) {
                padding-top: 25px;
            }
        }
        h2 {
            @include font-family-compressed;
            font-size: $size-3;
            text-transform: uppercase;
            margin-top: 0px;
            margin-bottom: 25px;
            @media screen and (max-width: calc($tablet - 1px)) {
                font-size: $size-4;
                margin-bottom: 25px;
            }
        }
        ul {
            margin: 0;
            list-style-type: none;
            li {
                line-height: 33px;
                a {
                    @include font-family-standard-bold;
                    color: $offblack;
                    &:hover,
                    &:focus {
                        color: $grey-60;
                    }
                }
            }
        }
        h3 {
            @include font-family-standard-bold;
            font-size: $size-4;
            margin-top: 0px;
            margin-bottom: 5px;
        }
    }

    .step-1 .progress-wrap,
    .step-2 .progress-wrap,
    .step-3 .progress-wrap{
        display: inline-flex;
        flex-direction: row-reverse;
        width: 100%;

        @media screen and (max-width: 767px) {
            display: block;
        }

        .continue {
          flex-grow: 1;
          width: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .back {
          flex: 0 0 auto;
          margin-right: 15px;
          padding: 0 25px;
          width: auto;
          @media screen and (max-width: 767px) {
            margin-right: 0px;
            width: 100%;
          }
        }
    }

    .sample-check{
        margin-top: 25px;
        img {
            max-width: 258px;
        }
    }

    // move section up if there is no yellow section above
    .donation-landing-section--nobg .container{
        padding-top: 0;
    }

    .footer-wrapper {
      margin-top: 0;
      &.hide-border {
        border-top: none;
      }
    }

    @media screen and (max-width: 768px) {
        .show-mobile-only {
          display: block;
        }
        .desktop-only {  // using bulma format plus custom rule any classes after this class are desktop only
          &.mt-xl {
            margin-top: 0 !important;
          }
        }
    }

    @media screen and (min-width: 768px) {
        #webform-component-donor-information--first-name,
        #webform-component-donor-information--last-name{
            display: inline-block;
            width: calc(50% - 7.5px);
            vertical-align: top;
        }
        div.form-item.error#webform-component-donor-information--last-name::after,
        div.form-item.error#webform-component-donor-information--first-name::after{
            top: 48px;
        }
        #webform-component-donor-information--first-name{
            margin-right: 15px;
        }
    }
}
.empty-section {
  display: none;
}

// custom styles for the EFT form
.multistep-donation-landing--eft{
    .reminder-display{
        display: none;
    }
    .node-donation-form > div.content .webform-client-form{
        position: relative !important;
    }
    .is-size-4{
        @include font-family-standard-bold;
        font-size: $size-4;
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .donation-landing-section.row1{
        display: none;
    }
}
// Helpers for showing and hiding
.mobile-only {
    @media screen and (min-width: 960px) {
        display: none;
    }
}
.desktop-tablet-only{
    @media screen and (max-width: 959px) {
        display: none;
    }
}
