.countdown-ticker {
  color: $dark-navy;
  position: relative;
  margin-top: -15px;
  margin-bottom: 15px;
}

.countdown-ticker .imminent-deadline .countdown-num,
.countdown-ticker .imminent-deadline .countdown-colon {
  color: $red-AA-compliant;
}

.countdown-ticker .countdown-col {
  display: inline-block;
  text-align: center;
  width: 54px;
  font-size: 60px;
  line-height: 48px;
  font-weight: 600;
  font-family: 'gtamcompressed', Arial, Helvetica, "Nimbus Sans L", sans-serif;
}

.countdown-ticker .countdown-col.countdown-colon {
  position: relative;
  top: -35px;
  width: 32px;
}

.countdown-ticker .countdown-unit {
  font-family: 'gtamstandard', Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
}

.goal-match-text {
  font-size: 21px;
}

@media only screen and (max-width: 999px) {
  .countdown-ticker {
    padding-top: 24px;
  }
  .countdown-ticker .countdown-col {
    font-size: 60px;
    line-height: 50px;
    width: 56px;
  }
  .countdown-ticker .countdown-col.countdown-colon {
    width: 21px;
    top: -28px
  }
  .countdown-ticker .countdown-unit {
    font-size: 18px;
    line-height: 20px;
    padding-top: 5px;
  }
  .goal-match-text {
    font-size: 16px;
    padding-bottom: 25px;
  }
}
