// variables
$bubble-dark: #120f54;
$bubble-light: #e5e5e5;

// Do not display the quote bubbles on desktop view
@media screen and (min-width: $desktop) {
    .bubble-con {
      display: none;
    }
}

// The container for the speech bubbles
.bubble-con {
    width: 500px;
    height: 300px;
    margin: 2rem 0 3rem 0;
    position: relative;
  
    @media screen and (min-width: $tablet) {
      margin: 2rem 0 3rem 0;
      width: 100%;
    }
    @media screen and (max-width: calc($tablet - 1px)) {
      margin: 1.3rem -15px 1rem -15px;
      width: calc(100% + 30px);
    }
  }
  
  .bubble {
    position: absolute;
  
    &.bubble--1 {
      left: 0;
      width: 55%;
      z-index: 1;
    }
  
    &.bubble--2 {
      right: 0;
      width: 50%;
    }
  }
   
  .speech-bubble {
    width: 100%;
    position: relative;
  
    &.speech-bubble--1 {
      background-color: $bubble-dark;
      color: $white;
      margin-top: 3rem;
      padding: 1.2rem 1.5rem 0.7rem 1.1rem;

      @media screen and (min-width: ($tablet)) {
        padding: 1.4rem 2rem 1rem 1.5rem;
      }
  
      &::before {
        border-top-color: $bubble-dark;
        left: 3rem;
      }
      .name {
        color: $white !important;
      }
    }
  
    &.speech-bubble--2 {
      background-color: $bubble-light;
      color: $bubble-dark;
      padding: 1.2rem 0.5rem 0.7rem 2.5rem;

      @media screen and (min-width: ($tablet)) {
        padding: 1.4rem 1.5rem 1rem 2.5rem;
      }
  
      &::before {
        border-top-color: $bubble-light;
        right: 3rem;
      }
      .name {
        color: $bubble-dark;
        @media screen and (max-width: calc($tablet - 1px)) {
          margin-left: 0.5rem;
        }
      }
    }
  
    &::before {
      border-top-width: 25px;
      border-top-style: solid;
      border-right: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 25px solid transparent;
      width: 50px;
      height: 50px;
  
      content: '';
      display: block;
      position: absolute;
  
      bottom: -49px;
    }
  
    // The top part of the quote bubble content
    &-topcon {
      margin-bottom: 1.3rem;
      p {
        font-size: 0.8rem;
        font-family: $aclu-serif;
        line-height: 1.4;
        letter-spacing: 0.01rem;
        font-weight: 400;
  
        &.quote {
          position: relative;
          &::before {
            content: '“';
            position: absolute;
            left: -0.7rem;
          }
        }

        @media screen and (min-width: ($tablet)) {
            font-size: 1rem;
        }
      }
    }
  
    // The bottom part of the quote bubble content
    &-bottomcon {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
  
      .name {
        margin-left: 1rem;
        &-text {
          font-weight: bold !important ;
          font-size: 1.1rem;
          margin: 0;
          line-height: 1.25;
  
          @media screen and (max-width: calc($tablet - 1px)) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  