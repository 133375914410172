#ocd-login-message, #ocd-login-message + a {
    display: none;
}

.node-type-donation-form .multistep-donation.default-form-wrapper .form-item-submitted-payment-information-payment-fields-credit-ocd {
    // click on this icon triggers tooltip
    .info-icon {
        display: inline-block;
        background-color: #000;
        color: #fff;
        height: 20px;
        width: 20px;
        cursor: pointer;
        border-radius: 10px;
        padding: 0 8px;
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
    }
    label.option {
        font-weight: 400;
        font-size: 14px;
        width: auto;
    }
    input.form-checkbox {
        height: auto;
    }
    // tooltip
    .description {
        position: absolute;
        top: -210px;
        left: 113px;
        z-index: 10;
        margin: 0;
        height: auto;
        width: 275px;
        clip-path: none;
        overflow: visible;
        background-color: #000;
        color: #fff;
        padding: 20px 25px;

        p {
            margin: 0 10px 0 0;
        }

        &.open {
            display: block !important;
        }

        .close-button {
            cursor: pointer;
            font-weight: bold;
            position: absolute;
            top: 8px;
            right: 5px;
            background-color: inherit;
            color: inherit;
            height: auto;
        }

        // down arrow for tooltip
        &:before {
            content: " ";
            position: absolute;
            top: calc(100% - 1px);
            left: calc(50% - 20px);
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #000;
        }
        
        // mobile tooltip
        @media (max-width: 999px) {
            left: 6px;
            width: 100%;
            max-width: 275px;
            &:before {
                left: 225px;
            }
        }
    }
}