body {
    .eoy-2020 {
        .body-mobile,
        .body-nonmobile,
        .page-title,
        .page-subtitle,
        p.disclaimer {
            text-align: right;
        }
        .page-title {
            h1 {
                margin-bottom: 0px;
                font-size: 60px;
                line-height: 50px;
            }
        }
        h2 .field-name-field-aclu-subtitle .field-item,
        .page-subtitle {
            font-size: 40px;
            line-height: 40px;
            font-family: $aclu-sans-compressed;
        }
        p.disclaimer {
            margin-top: 0px !important;
        }
        .field-name-body.right-content.field-type-text-with-summary {
            margin-top: 35px;
            padding-top: 15px;
            top: 0;
        }
        &.confirmation {
            .body-mobile,
            .body-nonmobile,
            .page-title,
            .page-subtitle {
                text-align: left;
            }
            .page-title {
                h1 {
                    margin-bottom: 45px;
                    font-size: 42px;
                    line-height: 1;
                }
            }
        }
        @media screen and (min-width: 1000px) and (max-width: 1200px) {
            .page-title {
                h1 {
                    font-size: 50px;
                    line-height: 50px;
                }
            }
            h2 .field-name-field-aclu-subtitle .field-item,
            .page-subtitle {
                font-size: 34px;
                line-height: 34px;
            }
        }
        @media screen and (max-width: 1000px) {
            .field-name-body.right-content.field-type-text-with-summary {
                margin-top: -20px;
            }
            .page-title {
                h1 {
                    font-size: 40px;
                    line-height: 40px;
                }
            }
            h2 .field-name-field-aclu-subtitle .field-item,
            .page-subtitle {
                font-size: 27px;
                line-height: 27px;
            }
        }
    }
}