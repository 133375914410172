body {
  // TYPOGRAPHY
  &,
  & li,
  & input,
  .error label {
    @include font-family-standard;
    font-size: $body-size;
    color: $offblack;
  }

  // Bold body text needs different letter-spacing, word-spacing
  .has-text-weight-bold,
  b,
  strong {
    @include font-family-standard-bold;
  }

  .has-text-weight-normal {
    @include font-family-standard;
  }

  // Headings
  h1,
  .is-h1 {
    @include font-family-compressed;
    font-size: $size-1;
    color: $offblack;

    &.is-size-2 {
      font-size: $size-2;
    }
    &.is-size-3 {
      font-size: $size-3;
    }
  }
  h2,
  .is-h2,
  h2 .field-name-field-aclu-subtitle .field-item {
    @include font-family-standard-bold;
    font-size: $size-4;
    color: $offblack;
  }
  h3,
  .is-h3  {
    @include font-family-standard-bold;
    font-size: $size-5;
    color: $offblack;
  }
  h4,
  .is-h4  {
    @include font-family-standard;
    color: $grey-40;
    font-size: $size-6;
  }

  // TODO: check these
  h5,
  h6 {
    @include font-family-standard-bold;
    color: $offblack;
  }

  // Section title
  .is-section-title,
  .page-title h1 {
    @include section-title;
    margin-bottom: 45px;
    @media screen and (max-width: calc($tablet - 1px)) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }


  // Body Text

  // Use these sparingly or not at all
  .has-font-family-compressed {
    @include font-family-compressed;
    // Compessed: to be used only with sizes 1, 2, 3
  }
  .has-font-family-standard {
    @include font-family-standard;
    // Standard: to be used only with sizes 4, 5, 6, 7

    &.has-text-weight-bold,
    .has-text-weight-bold,
    b,
    strong {
      @include font-family-standard-bold;
    }
  }
  .has-font-family-serif {
    @include font-family-serif;
    font-weight: $normal;
    // Serif: to be used only with sizes 6, 7, and a modified 5

    &.is-size-5 {
      font-size: 21px;
    }
    &.has-text-weight-bold,
    .has-text-weight-bold,
    b,
    strong {
      @include font-family-serif;
      font-weight: $bold;
    }
  }

  // Use these for convenience
  .is-body-text {
    @include font-family-standard;
    font-size: $size-5;

    &.has-text-weight-bold,
    .has-text-weight-bold,
    b,
    strong {
      @include font-family-standard-bold;
    }
  }

  .is-caption {
    @include font-family-serif;
    font-size: $size-6;
  }
  .is-credit {
    @include font-family-serif;
    font-size: $size-7;
    color: $grey-60;
  }
  .is-footnote {
    @include font-family-standard;
    font-size: $size-7;
    color: $offblack;
  }


  // UI Text
  // TODO

  // Links
  a {
    color: $blue;
    &:hover,
    &:focus {
      color: rgba(0,85,170,.75);
    }
    text-decoration: underline;

    &.text-link {
      @include font-family-standard-bold;
      color: $text;

      &:hover,
      &:focus {
        opacity: $hover-opacity;
      }
    }
  }
  nav a,
  button,
  .button,
  .no-underline {
    text-decoration: none !important;
  }
  nav a,
  button,
  .button {
    @include font-family-standard-bold;
  }
  button,
  .button {
    border: none;
    height: 45px;
    transition: opacity 0.25s;

    &.is-outlined {
      border: 2px solid $offblack;
    }

    &:hover {
      opacity: $hover-opacity;
      color: inherit;
    }
  }
  // Background colors
  .has-background-burgundy {
    background-color: $dark-burgundy;
  }
  .has-background-orange {
    background-color: $light-orange;
  }
  .has-background-yellow {
    background-color: $light-yellow;
  }

  // FORMS
  fieldset {
    border: none;
  }
  legend {
    display: table; // IE11
    white-space: normal; // IE
    width: 100%; // Edge
  }


  button,
  input:not[type="radio"],
  .button {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border-radius: 0;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: $inputHeight;
    min-width: $inputHeight;
    width: auto;
    line-height: 1.5;
    padding: calc(.375em - 1px) calc(.625em - 1px);
    position: relative;
    vertical-align: top;
    border: 2px solid $offblack;

    &.is-light {
      border: 2px solid $white;
      background-color: transparent;
    }

  }
  input.has-error {
    border: 2px solid $red-AA-compliant !important;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="search"],
  input.is-tall,
  button.is-tall,
  .button.is-tall {
    height: $inputHeightLarge;
    min-width: $inputHeightLarge;
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="search"]{
    width: 100%;
    border-radius: 0;
    padding: 5px;
    font-size: 19px;
  }

  // ICONS
  .icon {
    background-repeat: no-repeat;
    background-position: center;

    &.search {
      background-image: icon-url('icon-search-ffffff.svg');
    }

    &.caret,
    &.caret.down,
    &.caret.up {
      background-image: icon-url('icon-caret-ffffff.svg');
      background-size: 10px;
      transform: rotate(90deg);
    }
    &.up {
      transform: rotate(270deg);
    }
    // left and right carets used in links
    &.right,
    &.left {
      background-image: icon-url('icon-caret-0055aa.svg');
      transform: rotate(0deg);
      vertical-align: text-bottom;
      margin-top: 2px;
    }
    &.left {
      transform: rotate(180deg);
    }
    &.caret.is-light,
    &.caret.is-light:hover {
      background-image: icon-url('icon-caret-ffffff.svg');
    }
    &.caret.is-dark,
    &.caret.is-dark:hover {
      background-image: icon-url('icon-caret-231F20.svg');
    }

  }
  .round-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
  }

  // HELPERS
  .nowrap {
    white-space: nowrap;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .is-relative {
    position: relative;
  }

  // Centering helpers
  .vcenter-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .hcenter-content {
    display: flex;
    justify-content: center;
  }
  .vbottom-content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  // Accessibility
  .screenreader-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &.appear-on-focus:focus {
      position: static;
      overflow: visible;
      width: auto;
    }
  }

  .phone-with-icon:before {
    content: " ";
    background: icon-url("phone.png") no-repeat;
    background-size: 23px 23px;
    height: 23px;
    width: 23px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: 6px;
  }

  // Bulma Overrides

  // Fix tiles on IE11
  // per https://roland.codes/blog/ie-flex-collapse-bug/
  .tile {
    flex-basis: auto;
  }

  .container {
    padding: 0 $spacer;
  }

  #block-system-main {
      margin-top: 40px;
      @media screen and (max-width: calc($tablet - 1px)) {
          margin-top: 0px;
      }
  }

  // Overwrite Bulma's max container width on tablet
  @media screen and (min-width: $tablet) and (max-width: calc($desktop - 1px)) {
    .container {
      width: $tabletContainerWidth;
    }
  }

  // Overwrite Bulma's max container width on desktop
  @media screen and (min-width: $desktop) {
    .container {
      width: $desktopContainerWidth;
    }
  }

  // Overwrite Bulma's max container width on widescreen+
  @media screen and (min-width: $widescreen) {
    .container {
      width: $widescreenContainerWidth;
    }
  }

  // Springboard/bootstrap overrides
  label.error.valid {
    display: none !important;
  }

  div.error {
    background: none;
  }

  [class*="span"] {
    float: none;
    margin-left: 0px;
  }

  .disclaimer {
    font-size: 13px;
  }

  .tabs ul.tabs.primary {
    display: flex;
    flex-wrap: wrap;
  }
}
