#header {
  border-bottom: 1px solid $border;

  .branded-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: $navHeight;
    font-size: $size-5;
    padding-top: $navPadding;
    padding-bottom: $navPadding;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    background: $navBackgroundColor;

    .branded-nav-left {
      display: flex;
      flex-direction: row;
      text-align: left;
      align-items: stretch;
    }
    .nav-item {
      display: inline-block;
    }

    svg {
      height: $logoHeight;
      width: calc($logoHeight / $logoRatio);
    }
    
    /* Mobile */
    @media screen and (max-width: calc($tablet - 1px)) {
      svg {
        height: $mobileLogoHeight;
        width: calc($mobileLogoHeight / $logoRatio);
      }
    }
  }
}

/* Mobile only */
@media screen and (max-width: calc($tablet - 1px)) {
  #header .branded-nav {
    .branded-nav-left {
      align-items: flex-end;
    }
  }
}
